import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { AUTH_INFO_NAME } from "@/api/auth";

const SITE_NAME = "TestX";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        redirect: () => {
            if (localStorage.getItem(AUTH_INFO_NAME)) {
                return "/company";
            } else {
                return "/login";
            }
        },
        meta: { title: "", noLayoutHeader: true, notNeedAuth: true },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/login/LoginView.vue"),
        meta: { title: "Login", noLayoutHeader: true, notNeedAuth: true },
    },
    {
        path: "/logout",
        name: "Logout",
        component: () => import("../views/login/LogoutView.vue"),
        meta: { title: "Login", noLayoutHeader: true, notNeedAuth: true },
    },
    {
        path: "/request_token",
        name: "Request token",
        component: () => import("../views/login/RequestToken.vue"),
        meta: { title: "Login", noLayoutHeader: true, notNeedAuth: true },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/dashboard/DashboardView.vue"),
        meta: { title: "Dashboard", noLayoutHeader: true },
    },
    {
        path: "/company",
        name: "고객 관리",
        component: () => import("../views/company/CompanyManage.vue"),
        meta: { title: "고객 관리" },
    },
    {
        path: "/payment",
        name: "결제관리",
        redirect: "/payment/status",
        children: [
            { path: "status", name: "구독현황", component: () => import("../views/payment/SubscribeStatus.vue"), meta: { title: "구독현황" } },
            { path: "invoicing", name: "청구서 발행", component: () => import("../views/payment/InvoicingRegistration.vue"), meta: { title: "청구서 발행" } },
            { path: "invoiceList", name: "청구서 발행 내역 조회", component: () => import("../views/payment/InvoiceList.vue"), meta: { title: "청구서 발행 내역 조회" } },
            { path: "plan", name: "요금제 관리", component: () => import("../views/payment/PaymentPlan.vue"), meta: { title: "요금제 관리" } },
        ],
    },
    {
        path: "/facility",
        name: "설비 관리",
        redirect: "/facility/facilityRegistration",
        children: [
            { path: "facilityRegistration", name: "설비등록", component: () => import("../views/facility/FacilityRegistration.vue"), meta: { title: "설비등록" } },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const authInfoStr = localStorage.getItem(AUTH_INFO_NAME);
    const { notNeedAuth } = to.meta;

    if (notNeedAuth !== true && !authInfoStr) {
        return next({ path: "/login" });
    }
    next();
});

router.afterEach((to, from) => {
    if (to.meta && typeof to.meta.title === "string") {
        document.title = `${SITE_NAME} :: ${to.meta?.title}`;
    }
    // console.log(to);
    // Vue.nextTick(() => {
    //     console.log(to);
    // });
});

export default router;
