

import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, Ref, ref, watch } from "vue";
import * as util from "@/composables";
import { ConditionFunction, useComma, useIsNumber } from "@/composables";
import { SweetAlertResult } from "sweetalert2";

export enum ColumnType {
    select,
    button,
    iconButton,
    text,
    array,
    rowNumber
}

export type ParserFunction = (item: any | string, row: any) => string;

export interface ListDefine {
    label: string,
    key: string,
    type: ColumnType,
    isFixed?: boolean,
    isShow?: boolean,
    isSortable?: boolean,
    sort?: number,
    isOptional?: boolean,
    width?: number,
    align?: string,
    buttonHandler?: string,
    icon?: string,
    onClick?: string,
    clickableCondition?: ConditionFunction,
    formatter?: ParserFunction,
    isHtml?: boolean
}

export interface ListTableProp {
    totalCount: number,
    itemsPerPage: number,
    currentPage: number,
    rowId?: string,
    hasRowCheck?: boolean,
    checkLimit?: number,
    listDefine: Array<ListDefine>,
    list: Array<any>,
    height?: number | string
}

export default defineComponent({
    // emits: ["onSort", "showTestDetail", "onPageClicked", "onCheckChanged"],
    props: {
        listData: {
            type: Object as PropType<ListTableProp>,
            required: true,
        },
        clearTrigger: {
            type: Boolean,
            default: false,
        },
        hasListCount: {
            type: Boolean,
            default: true,
        },
        isSortable: {
            type: Boolean,
            default: true,
        },
        checkedList: {
            type: Array,
            default: () => [],
        },
    },
    setup (props, context) {
        // refs
        const listInfo: Ref<HTMLDivElement | null> = ref(null);
        const listTableBody: Ref<HTMLTableElement | null> = ref(null);
        const tableHeaderScroll: Ref<any | null> = ref(null);
        const tableBodyScroll: Ref<any | null> = ref(null);

        // variables
        const sortedCol = ref("");
        const isAsc = ref(false);
        const checkedRow: Ref<Array<string>> = ref([]);
        const bodyHeight = ref("");

        const tableWidth = computed(() => {
            let hasPadCol = false;
            let sumOfWidth = 0;
            for (let i = 0; i < props.listData.listDefine.length; i++) {
                const l = props.listData.listDefine[i];
                if (!l.width) {
                    hasPadCol = true;
                    break;
                }
                sumOfWidth += l.width;
            }
            if (hasPadCol) {
                return "100%";
            } else {
                return `${sumOfWidth}px`;
            }
        });

        // pagination
        const MAX_PAGINATION_COUNT = 10;
        const maxPage = computed(() => {
            if (props.listData && props.listData.totalCount && props.listData.itemsPerPage && props.listData.currentPage) {
                return Math.ceil(props.listData.totalCount / props.listData.itemsPerPage);
            }
            return 1;
        });
        const pageStart = computed(() => {
            if (maxPage.value > MAX_PAGINATION_COUNT) {
                if (maxPage.value < props.listData.currentPage + (MAX_PAGINATION_COUNT / 2)) {
                    return maxPage.value - MAX_PAGINATION_COUNT + 1;
                } else if (props.listData.currentPage <= (MAX_PAGINATION_COUNT / 2)) {
                    return props.listData.currentPage;
                } else {
                    return props.listData.currentPage - Math.floor(MAX_PAGINATION_COUNT / 2);
                }
            }
            return 1;
        });

        // methods
        const sortCol = (colKey: string) => {
            if (sortedCol.value === colKey) {
                isAsc.value = !isAsc.value;
            } else {
                sortedCol.value = colKey;
                isAsc.value = false;
            }
            context.emit("onSort", colKey, isAsc.value);
        };

        const getSortClass = (col: ListDefine): string => {
            if (!props.isSortable || col.type === ColumnType.button || col.type === ColumnType.iconButton || col.type === ColumnType.rowNumber || col.key.trim() === "") {
                return "no-sort";
            }
            if (sortedCol.value === col.key) {
                if (isAsc.value) {
                    return "sort-asc";
                } else {
                    return "sort-desc";
                }
            }
            return "";
        };

        const isClickableCell = (col: ListDefine, row: any) => {
            return col.onClick && (!col.clickableCondition || (col.clickableCondition && col.clickableCondition(row)));
        };

        const toggleCheckRow = (rowId: string, colType: ColumnType | null): boolean => {
            if (!props.listData.hasRowCheck) {
                return false;
            }
            if (colType != null && colType !== ColumnType.text) {
                return false;
            }
            const index = checkedRow.value.indexOf(rowId);
            if (index === -1) {
                if (props.listData.checkLimit && props.listData.checkLimit > 0 && props.listData.checkLimit <= checkedRow.value.length) {
                    const removedRowId = checkedRow.value[0];
                    checkedRow.value.splice(0, 1);
                    context.emit("onCheckChanged", checkedRow.value, false, removedRowId);
                }
                checkedRow.value.push(rowId);
                context.emit("onCheckChanged", checkedRow.value, true, rowId);
                return true;
            } else {
                checkedRow.value.splice(index, 1);
                context.emit("onCheckChanged", checkedRow.value, false, rowId);
                return false;
            }
        };

        const setCheckRowAll = () => {
            if (props.listData.list.length !== props.listData.totalCount) {
                util.useConfirm(`이 페이지에 있는 결과 ${props.listData.list.length}개가 모두 선택되었습니다. 전체 결과 ${props.listData.totalCount}개를 선택하시겠습니까?`)
                    .then((result: SweetAlertResult) => {
                        if (result.isConfirmed) {
                            props.listData.list.forEach(item => {
                                if (!checkedRow.value.includes(item[rowKey.value])) {
                                    checkedRow.value.push(item[rowKey.value]);
                                    context.emit("onCheckChanged", checkedRow.value, true, item[rowKey.value]);
                                }
                            });
                        } else if (result.isDenied) {
                            props.listData.list.forEach(item => {
                                if (!checkedRow.value.includes(item[rowKey.value])) {
                                    checkedRow.value.push(item[rowKey.value]);
                                    context.emit("onCheckChanged", checkedRow.value, true, item[rowKey.value]);
                                }
                            });
                        }
                    });
            } else {
                props.listData.list.forEach(item => {
                    if (!checkedRow.value.includes(item[rowKey.value])) {
                        checkedRow.value.push(item[rowKey.value]);
                        context.emit("onCheckChanged", checkedRow.value, true, item[rowKey.value]);
                    }
                });
            }
        };

        const calcBodyHeight = () => {
            if (props.listData.height) {
                if (listInfo.value && listInfo.value.clientHeight) {
                    const listInfoStyle = window.getComputedStyle(listInfo.value);
                    const listInfoHeight = listInfo.value.clientHeight + parseFloat(listInfoStyle.marginTop) + parseFloat(listInfoStyle.marginBottom);
                    if (typeof props.listData.height === "number") {
                        bodyHeight.value = `calc(${props.listData.height}px - ${listInfoHeight}px)`;
                    } else {
                        bodyHeight.value = `calc(${props.listData.height} - ${listInfoHeight}px)`;
                    }
                }
            } else {
                bodyHeight.value = "auto";
            }
        };
        const onPageClicked = (page: number) => {
            context.emit("onPageClicked", page);
        };

        const setTableCellHint = () => {
            // listTableBody.value?.querySelectorAll<HTMLTableCellElement>("tbody td").forEach(td => {
            //     if (td.offsetWidth < td.scrollWidth) {
            //         td.setAttribute("data-hint", td.innerText);
            //     } else {
            //         td.removeAttribute("data-hint");
            //     }
            // });
        };
        const onTableScrollX = (event: any) => {
            if (tableHeaderScroll.value) {
                tableHeaderScroll.value.ps.element.scrollLeft = event.target.scrollLeft;
            }

            if (tableBodyScroll.value) {
                tableHeaderScroll.value.ps.element.scrollLeft = event.target.scrollLeft;
            }

            // console.log(event.srcElement.scrollLeft);
        };

        watch(() => props.clearTrigger, () => {
            checkedRow.value = [];
            // context.emit("onCheckChanged", checkedRow.value);
        });

        watch(() => props.checkedList, () => {
            checkedRow.value = props.checkedList.map((i: any) => i[rowKey.value]);
        }, { deep: true });

        const rowKey = computed(() => {
            if (props.listData.rowId && props.listData.rowId.trim() !== "") {
                return props.listData.rowId;
            }
            return "key";
        });

        const makeViewText = (col: any, row: any) => {
            const cellValue = row[col.key];
            if (col.formatter) {
                return col.formatter(cellValue, row);
            } else {
                return !useIsNumber(cellValue) ? cellValue : useComma(cellValue);
            }
        };

        // evnet handler
        onMounted(() => {
            calcBodyHeight();
            if (props.checkedList.length > 0) {
                checkedRow.value = props.checkedList.map((i: any) => i[rowKey.value]);
            }

            setTableCellHint();
        });

        onBeforeUnmount(() => {
            // on before unmount
        });

        return {
            tableHeaderScroll,
            tableBodyScroll,
            listInfo,
            bodyHeight,
            tableWidth,
            listTableBody,

            ColumnType,
            sortedCol,
            isAsc,
            checkedRow,

            pageStart,
            maxPage,
            MAX_PAGINATION_COUNT,

            getSortClass,
            sortCol,
            toggleCheckRow,
            setCheckRowAll,

            onPageClicked,

            rowKey,
            makeViewText,
            onTableScrollX,
            isClickableCell,
        };
    },
});
