import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import "sweetalert2/dist/sweetalert2.min.css";

import resistUIKit from "@/components/UIKit";
import "@vuepic/vue-datepicker/dist/main.css";
import { useComma, useIsNumber } from "@/composables";
import { Code } from "@/code";

const app = createApp(App);

resistUIKit(app);

app.use(store)
    .use(router)
    .use(PerfectScrollbar)
    .mount("#app");

app.config.globalProperties.$filters = {
    comma (value: number | string) {
        return useComma(value);
    },
    isNumber (value: number | string) {
        return useIsNumber(value);
    },
};
