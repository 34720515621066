import { createStore } from "vuex";

export default createStore({
    state: {
        theme: "light",
        authInfo: null
    },
    getters: {},
    mutations: {
        clearAuthInfo: (state) => {
            state.authInfo = null;
        }
    },
    actions: {},
    modules: {}
});
