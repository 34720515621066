import axios, { requestGet } from "./index";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import store from "../store/index";

export const AUTH_INFO_NAME = "testx-admin.auth.info";

export const ApiAuth = {
    /**
     * 최초 access token 요청
     * @param code
     */
    getToken (code: string) {
        console.log("issue access token");
        // TODO: move to back-end server
        const requestData = {
            grant_type: "authorization_code",
            client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
            redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URL,
            code: code,
        };

        return ApiAuth.requestToken(requestData);
    },

    getUserInfo () {
        console.log("request me!");
        return requestGet(`${process.env.VUE_APP_API_SERVER}/user_service/v1/me`);
    },

    /**
     * refresh token으로 access token 요청
     */
    reIssueToken: (reqConfig: AxiosRequestConfig) => {
        console.log("re-issue access token");
        // TODO: move to back-end server
        const tokenInfo = ApiAuth.getTokenInfo();
        if (tokenInfo === null) router.replace("/login").catch();

        const requestData = {
            grant_type: "refresh_token",
            client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
            refresh_token: tokenInfo.refresh_token,
        };

        return new Promise((resolve, reject) => {
            ApiAuth.requestToken(requestData).then((response: AxiosResponse) => {
                if (response.status === 200) {
                    localStorage.setItem(AUTH_INFO_NAME, JSON.stringify({
                        ...tokenInfo,
                        ...response.data,
                    }));

                    resolve(response.data.access_token);
                } else {
                    localStorage.removeItem(AUTH_INFO_NAME);
                    router.replace("/login");
                    reject(response);
                }
            });
        });
    },

    /**
     * access token 요청
     * @param requestData
     */
    requestToken: (requestData: any) => {
        const TOKEN_URL = `${process.env.VUE_APP_AUTH_URL}${process.env.VUE_APP_AUTH_TOKEN_PATH}`;

        const axiosConfig: AxiosRequestConfig = {};
        const authorization = btoa("9fopju6k4lc9ln2rlvnsmso8t:om06ffp29cbqnt0vkmb5ncpr386jut5mhhu22e43iicocs1ruv0");
        axiosConfig.headers = {
            Authorization: `Basic ${authorization}`,
            "Content-Type": "application/x-www-form-urlencoded",
        };

        return new Promise<AxiosResponse>(resolve => {
            axios.post(TOKEN_URL, requestData, axiosConfig).then(response => {
                resolve(response);
            }).catch(error => {
                console.log(error);
            });
        });
    },

    getTokenInfo: () => {
        const authInfoStr = localStorage.getItem(AUTH_INFO_NAME);
        if (!authInfoStr) {
            return null;
        } else {
            try {
                const authInfo = JSON.parse(authInfoStr);
                if (!authInfo.access_token || !authInfo.refresh_token) {
                    return null;
                } else {
                    return authInfo;
                }
            } catch (e) {
            }
        }

        return null;
    },

    logout: () => {
        store.commit("clearAuthInfo");
        localStorage.removeItem(AUTH_INFO_NAME);
        location.href = `${process.env.VUE_APP_AUTH_URL}/logout?response_type=code&client_id=${process.env.VUE_APP_AUTH_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT_URL}&scope=${process.env.VUE_APP_AUTH_SCOPE}&logout_uri=${process.env.VUE_APP_AUTH_LOGOUT_REDIRECT_URL}`;
    },
};
